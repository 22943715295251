import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { searchBox, hits } from 'instantsearch.js/es/widgets';

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

const search = instantsearch({
  indexName: ALGOLIA_PRODUCT_INDEX_NAME,
  searchClient,
  searchFunction(helper) {
    const query = helper.state.query;

    if (query) {
      helper.search();
    }

    document.querySelector('#hits').style.display = query ? 'block' : 'none';
  },
});

// Add widgets
search.addWidget(
  searchBox({
    container: '#search-box',
    placeholder: 'Turret Search (e.g. Zeiss Conquest 4-16x50)'
  })
);

search.addWidget(
  hits({
    container: '#hits',
    templates: {
      item: `
        <a href="{{product_path}}" class="search-item">
          <div class="search-item-block">
            <div class="search-item-image">
              <img src="{{image_url}}" alt="{{productName}}" class="w-100">
            </div>
            <div class="text-left">
              <h6 class="uppercase">{{#helpers.highlight}}{ "attribute": "brand_name", "highlightedTagName": "mark" }{{/helpers.highlight}}</h6>
              <h4 class="mb-0">{{#helpers.highlight}}{ "attribute": "product_name", "highlightedTagName": "mark" }{{/helpers.highlight}}</h4>
              <p class="capitalize">
                {{#helpers.highlight}}{ "attribute": "name", "highlightedTagName": "mark" }{{/helpers.highlight}}
                | $<strong>{{#helpers.highlight}}{ "attribute": "price", "highlightedTagName": "mark" }{{/helpers.highlight}}</strong>
              </p>
            </div>
          </div>
        </a>
      `
    }
  })
);

// Start the search UI
search.start();